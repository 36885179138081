
.box{
  position: fixed;
  background:linear-gradient(325.17deg, #78EA83 11.28%, #51BF59 34.25%, #259528 79.61%);
}

.box-1{
  position: fixed;
  background: linear-gradient(11.02deg, rgba(120, 234, 131, 0.1) -19.74%, rgba(37, 149, 40, 0.1) 64.4%);
}

.box-2{
  position: fixed;
  background: linear-gradient(11.03deg, rgba(120, 234, 131, 0.1) -19.86%, rgba(37, 149, 40, 0.1) 64.37%);
}

.shape {
  position: fixed;
  overflow: hidden;
  height: 80px;
  /* filter: drop-shadow(4px 4px 10px rgba(0,0,0,0.5)); */
}

.shape::before {
  border-radius: 100%;
  position: absolute;
  background:linear-gradient(325.17deg, #78EA83 11.28%, #51BF59 34.25%, #259528 79.61%);
  right: -1000px;
  left: -500px;
  top: -200px;
  content: '';
  bottom: 0;
}

.shape-1 {
  position: fixed;
  overflow: hidden;
  height: 80px;
  /* filter: drop-shadow(4px 4px 10px rgba(0,0,0,0.5)); */
}

.shape-1::before {
  border-radius: 100%;
  position: absolute;
  background: linear-gradient(11.02deg, rgba(120, 234, 131, 0.1) -19.74%, rgba(37, 149, 40, 0.1) 64.4%);
  right: -1000px;
  left: -500px;
  top: -200px;
  content: '';
  bottom: 0;
}

.shape-2 {
  position: fixed;
  overflow: hidden;
  height: 80px;
  /* filter: drop-shadow(4px 4px 15px rgba(0,0,0,0.5)); */
}

.shape-2::before {
  border-radius: 100%;
  position: absolute;
  background: linear-gradient(11.03deg, rgba(120, 234, 131, 0.1) -19.86%, rgba(37, 149, 40, 0.1) 64.37%);
  right: -1000px;
  left: -500px;
  top: -200px;
  content: '';
  bottom: 0;
}

.header {
  text-align: center;
  font-family: 'Nats-Regular';
  font-size: 48px;
  margin-bottom: 0px;
  color: #ffffff;
  margin: 20px;
}

.std-text {
  font-family: 'Nats-Regular';
  font-size: 20px;
  text-align: center;
  color: #ffffff;
}

.center-horz {
  justify-content: center;
}

.center {
  justify-content: center;
  align-items: center;
}
