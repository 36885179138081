.button {
    font-family: 'NATS-Regular';
    color: #78ea83;
    font-size: 28px;
    padding-bottom: 10px;
}

.headerTitle {
    text-align: right;
    font-family: 'NATS-Regular';
    font-size: 28px;
}

.headerTitle_small {
    text-align: right;
    font-family: 'NATS-Regular';
    font-size: 22px;
}

.headerButton {
    color: #292929;
    font-family: 'NATS-Regular';
    padding: 5px 10px;
    background-color: #ffffff;
    border-radius: 0%;
    border-color: #ffffff;
}

.popoverTitle {
    background-color: #ffffff;
    border-color: #ffffff;
    font-size: 30px;
    font-family: 'NATS-Regular';
}
